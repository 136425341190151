
.footer__top {
    padding-top: 112px;
    padding-bottom: 137px;
  }
  @media (max-width: 961px) {
    .footer__top .container {
      row-gap: var(--offset);
    }
  }
  @media (max-width: 961px) {
    .footer__top {
      padding: 60px 0;
    }
  } 
  .footer__bottom {
    padding-top: 40px;
    padding-bottom: 63px;
    background-color: #000f0f;
  }
  .footer__bottom-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  @media (max-width: 961px) {
    .footer__bottom-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }
  .footer__bottom-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  @media (max-width: 961px) {
    .footer__logo {
      margin-bottom: 20px;
    }
  }
  .footer__column {
    -ms-grid-column-span: 2;
    grid-column: 2 span;
  }

  .footer__wr-logo {
    translate: 16% 0;
  }

  @media (max-width: 961px) {
    .footer__wr-logo {
      translate: none;
    }
  }

  .footer__column:last-child {
    grid-column: 11/2 span;
  }
  
  @media (max-width: 961px) {
    .footer__column:last-child {
      -ms-grid-column-span: 2;
      grid-column: 2 span;
    }
  }

  .footer__link {
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #edeceb;
    -webkit-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out;
  }
  .footer__link:focus {
    outline: none;
  }

  /* @media (any-hover: hover) {
    .footer__link:hover {
      color: var(--accent-color);
    }
  } */

  .footer__link:active {
    color: var(--active-color);
  }

  .footer__title {
    position: relative;
    margin: 0;
    margin-bottom: 23px;
    padding-bottom: 24px;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #7d9798;
  }
  .footer__title::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 16px;
    height: 1px;
    background-color: #25b895;
  }
  .footer__sublist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 8px;
    gap: 8px;
  }
  .footer__sublist-link {
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    color: #e5ecec;
    -webkit-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out;
  }
  .footer__sublist-link:focus {
    outline: none;
  }
  @media (any-hover: hover) {
    .footer__sublist-link:hover {
      color: var(--accent-color);
    }
  }
  .footer__sublist-link:active {
    color: var(--active-color);
  }
  @media (max-width: 961px) {
    .footer__sublist-link {
      font-size: 14px;
    }
  }
  @media (max-width: 961px) {
    .footer__sublist {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
  .footer__copy {
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    color: #e5ecec;
  }
  .footer__rights {
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #e5ecec;
  }
  
  .footer__sublist-link.focus-visible {
    outline: 1px solid var(--active-color);
    outline-offset: 2px;
  }
  
  .footer__link.focus-visible {
    outline: 1px solid var(--active-color);
    outline-offset: 2px;
  }