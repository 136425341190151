.about-brand__wr-slider {
    margin: 0;
    padding: 0; 
    box-sizing: border-box;

    font-style: normal;
    font-weight: 400;
 
    list-style-type: none;
}

.about-brand__wr-slider a {
    display: inline-block;
    width: 100%;
    height: 100%;

    text-decoration: none;

    color: #000;
}


/* ------------------------------------------- */

.about-brand__wr-slider {
    display: flex;
    width: 84.17vw;
    min-width: 0;
    margin-top: 16.2vw;
    margin-bottom: 9.06vw;
    margin-left: auto;
    margin-right: auto;

    align-items: center;
}

.about-brand__wr-slider_mobile {
    display: none;
}

.about-brand__slider-container_desctop { 
    display: flex;
    margin-top: 5.31vw;
    justify-content: center;
}

.about-brand__button-prev-icon {
    width: 4.17vw;
    height: 4.17vw;

    cursor: pointer;

}

div.about-brand__slide {
    height: 21.88vw;
}

.about-brand__slide-1 {
    background: no-repeat url(../img/content/brand-slide1.png);
    background-size: contain;
    background-position: center;
}

.about-brand__slide-2 {
    background: no-repeat url(../img/content/brand-slide2.png);
    background-size: contain;
    background-position: center;
}

.about-brand__slide-3 {
    background: no-repeat url(../img/content/brand-slide3.png);
    background-size: contain;
    background-position: center;
}

.about-brand__slide-4 {
    background: no-repeat url(../img/content/brand-slide4.png);
    background-size: contain;
    background-position: center;
}

.about-brand__slide-5 {
    background: no-repeat url(../img/content/brand-slide5.png);
    background-size: contain;
    background-position: center;
}

.swiper-pagination {
    display: none;
}

.about-brand__button-next-icon {
    width: 4.17vw;
    height: 4.17vw;

    cursor: pointer;
    z-index: 300;
}

/* src/img/icons/rightarrow-main-slide.svg */
@media (max-width: 961px) {
    .about-brand__wr-slider {
        width: 100%;
        margin-top: 16.53vw;
        margin-left: 0;
        margin-right: 0;


        flex-direction: column;
        align-items: center;
    }

    .about-brand__wr-slider_mobile {
        display: block;
    }

    .about-brand__wr-slider_desctop {
        display: none;
    }
}

@media (max-width: 961px) {
    .about-brand__swiper_mobile {
        min-width: 0;
        width: 100%;
    }
}

@media (max-width: 961px) {
    div.about-brand__slide {
        height: 62vw;
    }
}

/* @media (max-width: 961px) {
    .swiper-pagination {
        position: static;

        display: block;
    }

    .swiper-pagination span {
        border: 1px solid #ffffff !important;
        background: none;
    }

    .swiper-pagination-bullet-active {
        background: #fff !important;
    }
} */

@media (max-width: 961px) {
    .about-brand__button-icon {
        display: none;
    }
}