.mod-t-art__wrapper {
    display: flex;

    flex-direction: column;
    align-items: center;
}

.mod-t-art__title { 
    margin-bottom: 0;

    font-family: Arsenal;
    font-size: 2.34vw;
    font-style: normal;
    font-weight: 400;
    text-align: center;

    color: #F5C726;
}

.mod-t-art__wr-img-top {
    width: 61.46vw;
    height: 24.38vw;
    margin-top: 3.13vw;

    border-radius: 2.60vw;
    box-shadow: 0px 0px 17px rgb(0 25 10 / 80%);

    overflow: hidden;
}

.mod-t-art__img-top {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
}

.mod-t-art__wr-title_mob {
    display: none;
}

.mod-t-art__wr-text {
    display: flex;
    width: 61.46vw;
    margin-top: 2.55vw;
    gap: 1.25vw;

    flex-direction: column;
}

.mod-t-art__wr-text p {
    margin-bottom: 0;

    font-family: Arsenal;
    font-size: 1.06vw;
    font-style: normal;
    font-weight: 400;
    text-align: center;

    color: #FFF;
}

.mod-t-art__media-bottom {
    width: 62%;
}

.mod-t-art__img-bottom-list {
    padding-left: 0;
    margin-top: 4.43vw;
}

/* картинок больше одной устанвливается в js */
.mod-t-art__img-bottom-list_more {
    display: flex;

    flex-wrap: wrap;
    justify-content: space-between;
}

.mod-t-art__img-bottom-list_unactive {
    display: none;
}

.mod-t-art__wr-img-bottom-item {
    border-radius: 2.60vw;
}

.mod-t-art__wr-img-bottom-item:nth-child(n + 3) {
    margin-top: 2.4vw;
}

.mod-t-art__wr-img-bottom-item_one {
    width: 61.46vw;
    height: 24.38vw;
}

.mod-t-art__wr-img-bottom-item_more {
    width: 29.43vw;
    min-height: 21.93vw;
}

.mod-t-art__img-bottom {
    width: 100%;
    height: 100%;

    border-radius: 2.60vw;
    box-shadow: 0px 0px 17px rgb(0 25 10 / 80%);

    object-fit: cover;
    object-position: center;
}

.mod-t-art__wr-img-bottom-item_more img {
    height: 21.93vw;
}

.mod-t-art__img-bottom-description {
    margin-top: 0.8vw;

    font-family: Blogger Sans;
    font-size: 1.04vw;
    text-align: center;

    color: #ffffff;
}

.mod-t-art__wr-video {
    display: flex;
    width: 61.46vw;
    margin-top: 4.43vw;

    flex-direction: column;
    align-items: center;

    overflow: hidden;
}

/* видео больше одного устанавливается в js */
.mod-t-art__wr-video_more {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.mod-t-art__wr-video iframe {
    border-radius: 2vw;
    box-shadow: 0px 0px 17px rgb(0 25 10 / 80%);
}

.mod-t-art__wr-video iframe:nth-child(n+3) {
    margin-top: 2.4vw;
}

/* когда видео больше одного */
.mod-t-art__video_more {
    width: 29.43vw;
    height: 21.93vw;
}

/* для нечетного количества видео */
.mod-t-art__wr-video_odd iframe:last-child {
    margin: 2.4vw auto 0;
}

.mod-t-art__wr-video_unactive {
    display: none;
}

@media(max-width: 961px) {
    .mod-t-art__wr-title_desc {
        display: none;
    }
}

@media(max-width: 961px) {
    .mod-t-art__wr-img-top {
        width: 89.33vw;
        height: 88vw;
        margin-top: 7vw;
    
        border-radius: 8vw;
    }
}

@media(max-width: 961px) {
    .mod-t-art__wr-title_mob {
        display: block;
        margin-top: 6.5vw;
    }

    .mod-t-art__title {
        font-size: 7.47vw;
    }
}

@media(max-width: 961px) {
    .mod-t-art__wr-text {
        width: 89.33vw;
        margin-top: 2.40vw;
        gap: 3.47vw;
    }
}

@media(max-width: 961px) {
    .mod-t-art__wr-text p {
        font-size: 4.80vw;
    }
}

@media(max-width: 961px) {
    .mod-t-art__media-bottom {
        width: 89.33vw;
    }
}

@media(max-width: 961px) {
    .mod-t-art__img-bottom-list {
        margin-top: 10vw;

        flex-direction: column;
    }

    .mod-t-art__img-bottom-list_more {
        gap: 6.67vw;
    }
}

@media(max-width: 961px) {
    .mod-t-art__wr-img-bottom-item {
        min-height: 71.73vw;
        width: 100%;

        border-radius: 8vw;
    }

    .mod-t-art__wr-img-bottom-item_more img {
        height: 71.73vw;
    }

    .mod-t-art__wr-img-bottom-item:nth-child(n + 3) {
        margin-top: 0;
    }
}

@media(max-width: 961px) {
    .mod-t-art__img-bottom {
        border-radius: 8vw;   
    }
}

@media(max-width: 961px) {
    .mod-t-art__img-bottom-description {
        font-size: 5.33vw;
    }
}

@media(max-width: 961px) {
    .mod-t-art__wr-video {
        width: 100%;
        margin-top: 10vw;

        flex-direction: column;
    }

    .mod-t-art__wr-video_more {
        gap: 6.67vw;

        justify-content: normal;
        flex-wrap: nowrap;
    }
}

@media(max-width: 961px) {
    .mod-t-art__wr-video iframe {
        width: 100%;
        height: 71.73vw;
        margin: 0;

        border-radius: 8vw;
    }

    .mod-t-art__wr-video iframe:nth-child(n+3) {
        margin: 0;
    }

    .mod-t-art__wr-video_odd iframe:last-child {
        margin: 0;
    }
}

