.tea-sl__wrapper {
    display: flex;
    height: 26.04vw;

    justify-content: space-between;
}

.tea-sl__wr-button {
    display: flex;

    align-items: center;
    justify-content: center;
}

.tea-sl__button { 
    width: 4.17vw;
    height: 4.17vw;

    cursor: pointer;
}

.tea-sl__wr-slides {
    width: 65.10vw;

    overflow: hidden;
}

.tea-sl__wr-slide-list {
    display: flex;
    width: 100%;
    height: 100%;
    padding-left: 0;
    margin-bottom: 0;
}


.tea-sl__slide-item {
    position: relative;

    display: flex;

    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.tea-sl__slide-item img {
    width: 100%;
    height: 86%;

    object-fit: contain;
}

.tea-sl__slide-item_dellipack-tea-pot img {
    height: 61%;
}

.tea-sl__slide-item-prev img {
    transform: translate(-11.5%, -3%);
}

.tea-sl__slide-item-center img {
    height: 100%;

    transform: translate(0, 0);
}

.tea-sl__slide-item_dellipack-tea-pot.tea-sl__slide-item-center img {
    height: 81%;
}

.tea-sl__slide-item-next img {
    transform: translate(11.5%, -3%);
}

.tea-sl__link {
    position: absolute;
    height: 100%;
    width: 100%;
}

/* Для неактивного слайдера когда мало элементов */
.tea-sl__unactive.tea-sl__wrapper {
    max-height: 32.76vw;
    justify-content: center;
}

.tea-sl__unactive .tea-sl__wr-slide-list {
    justify-content: center;
	gap: 4vw;
}

.tea-sl__unactive .tea-sl__slide-item {
    flex-shrink: 1;
}

.tea-sl__unactive .tea-sl__slide-item img {
    width: 80%;
    height: 100%;
    object-fit: contain;
}

