.prc__wrapper {
    display: flex;
    width: 100%;

    flex-direction: column;
    align-items: center;
}

.prc__wr-title {
    margin-top: 2.60vw;
}

.prc__title {
    display: flex;
    margin-bottom: 0;

    font-family: Arsenal;
    font-size: 2.08vw;
    font-weight: 400;
    text-align: center;

    color: #fefefe;

    flex-direction: column;
}

.prc__about-product {
    display: flex;
    gap: 4vw;
    width: 84.38vw;
    margin-top: 6.21vw;

    justify-content: center;
    align-items: center;
}

.prc__wr-product-img {
    position: relative;

    width: 36.46vw;
    height: 37.45vw;
}

.prc__wr-product-img_pyramid {
    height: 27.60vw;
}

.prc__wr-product-img_dellipack {
    height: 29.06vw;
}

.prc__wr-product-img_tea-pot {
    height: 21.61vw;
}

.prc__wr-product-img_leaf {
    height: 29.90vw;
}

.prc__wr-product-img_top-selection {
    height: 29.01vw;
}

.prc__product-img-animate {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    z-index: -1;
}

.prc__product-img-animate_pyramid {
    width: 100%;
}

.prc__product-img-animate_dellipack {
    width: 95%;
}

.prc__wrapper_dellipack-hor-earl-grey .prc__product-img-animate{
    width: 80%;
}

.prc__product-img-animate_leaf {
    width: 94%;
}

#prc__product-img-animate_leaf-green {
    transform: translate(-54%, -50%);
}

.prc__product-img-animate_top-selection {
    width: 100%;
}

.prc__product-img {
    width: 100%;
    height: 100%;

    object-fit: contain;
    object-position: center;
}

.prc__wr-product-description_back {
    display: flex;
    width: 32vw;
    height: fit-content;
    padding: 1px;

    background: linear-gradient(180deg, #00BD8D, #00392D);
    border-radius: 2.60vw;
    box-shadow: 0px 0px 0px 0px rgba(0, 25, 19, 0.10),
     13px 13px 41px 0px rgba(0, 25, 19, 0.10),
      54px 52px 75px 0px rgba(0, 25, 19, 0.09),
       121px 118px 101px 0px rgba(0, 25, 19, 0.05),
        215px 209px 120px 0px rgba(0, 25, 19, 0.01),
         336px 327px 131px 0px rgba(0, 25, 19, 0.00);
}

.prc__wr-product-description_back_pyramid {
    height: 29.06vw;
    width: 29.06vw;
}

.prc__wr-product-description_back_dellipack {
    height: 29.06vw;
    width: 29.06vw;
}

.prc__wr-product-description_back_tea-pot {
    height: 27.06vw;
    width: 27.06vw;
}

.prc__wr-product-description_back_leaf {
    height: 29.90vw;
    width: 29.90vw;
}

.prc__wr-product-description_back_top-selection {
    height: 29.06vw;
    width: 29.01vw;
}

.prc__wr-product-description {
    position: relative;

    display: flex;
    width: 100%;
    padding: 0 2.03vw 0 1.51vw;

    background: linear-gradient(180deg, #7775B6, #494694);
    border-radius: 2.60vw;

    flex-direction: column;
    justify-content: center;
}

.prc__wr-product-description-text { 
    display: flex;
    gap: 0.5vw;

    flex-direction: column;
}

.prc__wr-product-description-text p { 
    margin-bottom: 0;

    font-family: Arsenal;
    font-size: 1.06vw;
    font-weight: 400;

    color: #ffffff;
}

.prc__wr-product-description-text_dellipack-assorti p {
    font-size: 0.95vw;
}

.prc__wr-product-description-text_pyramid-tropic p {
    font-size: 1vw;
}

.prc__wr-product-description-text_pyramid-berries p {
    font-size: 1.02vw;
}

.prc__wr-product-description-text_top-cedar p {
    font-size: 0.85vw;
}

.prc__wr-product-description-text_shu-puer-bing-cha p {
    font-size: 0.85vw;
}

.prc__wr-product-description-text_dell-pot-thyme p {
    font-size: 0.93vw;
}

.prc__wr-product-description-text_dell-pot-jasmine p {
    font-size: 1.01vw;
}

.prc__wr-product-description-text_leaf-sbiten p {
    font-size: 1vw;
}

.prc__wr-product-description-text_leaf-fireweed p {
    font-size: 1.04vw;
}

.prc__wr-product-description-text_leaf-herbal-mix-3 p {
    font-size: 1.01vw;
}

.prc__wr-product-description-text_leaf-sagan-dale p {
    font-size: 0.85vw;
}

.prc__wr-product-description-text_leaf-altaic-herbs p {
    font-size: 1vw;
}

.prc__wr-product-description-text_leaf-cascara p {
    font-size: 1.04vw;
}

.prc__wr-product-description-text_leaf-gaba-oolong p {
    font-size: 0.9vw;
}

.prc__wr-product-description-text_leaf-rosehip p {
    font-size: 1vw;
}

.prc__wr-product-description-text_leaf-hibiscus p {
    font-size: 1vw;
}

.prc__wr-product-description-text_leaf-rooibush p {
    font-size: 1vw;
}

.prc__amount-weight-list {
    display: flex;
    margin-top: 1.61vw;
    margin-bottom: 0;
    padding-left: 0;
    gap: 2.08vw;
}

.prc__wr-amount-weight-item {
    display: flex;
    
    flex-direction: column;
    align-items: center;
}

.prc__wr-amount-weight-icon {
    height: 3.65vw;
    width: 3.65vw;
}

.prc__wr-amount-weight-text {
    font-family: Arsenal;
    font-size: 1.06vw;
    font-weight: 400;
    text-align: center;

    color: #ffffff;
}

.prc__wr-title-brewing-tea {
    margin-top: 1.51vw;
}

.prc__title-brewing-tea {
    margin-bottom: 0;

    font-family: Arsenal;
    font-size: 1.06vw;

    color: #ffffff;
}

.prc__method-brewing-list {
    display: flex;
    margin-top: 1vw; /*1.61*/
    margin-bottom: 0;
    padding-left: 0;
    gap: 2.08vw;
}

.prc__method-brewing-item {
    display: flex;

    flex-direction: column;
    align-items: center;
}

.prc__method-brewing-icon {
    height: 3.65vw;
    width: 3.65vw;
}

.prc__method-brewing-text {
    font-family: Arsenal;
    font-size: 1.06vw;
    font-weight: 400;
    text-align: center;

    color: #ffffff;
}

.star-prod-card {
    width: 1.5vw;
    height: 1.41vw;
}

.prc__star-top {
    position: absolute;
    right: 7.69vw;
    bottom: 14.47vw;
}

.prc__star-bottom {
    position: absolute;
    right: 3.67vw;
    bottom: 10.93vw;
}




@media (max-width: 961px) {
    .prc__wr-title {
        width: 91vw;
        margin-top: 1.09vw;
    }
}

@media (max-width: 961px) {
    .prc__title {
        font-size: 7.47vw;
    }
}

@media (max-width: 961px) {
    .prc__about-product {
        margin-top: 14.27vw;
        gap: 16.40vw;

        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 961px) {
    .prc__wr-product-img {
        width: 100%;
        height: 94vw;
    }

    .prc__wr-product-img_pyramid {
        height: 72vw;
    }

    .prc__wr-product-img_dellipack {
        height: 93vw;
    }

    .prc__wr-product-img_tea-pot {
        height: 62vw;
    }

    .prc__wr-product-img_top-selection {
        height: 92vw;
    }
}

@media (max-width: 961px) {
    .prc__product-img-animate_pyramid {
        width: 110%;
    }
}

@media (max-width: 961px) {
    .prc__product-img-animate_dellipack {
        width: 115%;
    }
}

@media (max-width: 961px) {
    .prc__product-img-animate_tea-pot {
        width: 115%;
    }
}

@media (max-width: 961px) {
    .prc__product-img-animate_leaf {
        width: 112%;
    }

    #prc__product-img-animate_leaf-light-green {
        width: 116%;
    }
}

@media (max-width: 961px) {
    .prc__product-img-animate_top-selection {
        width: 115%;
    }
}

@media (max-width: 961px) {
    .prc__wr-product-description_back {
        width: 89.33vw;
        height: auto;

        border-radius: 8vw;
    }
}

@media (max-width: 961px) {
    .prc__wr-product-description {
        padding: 9.07vw 7.20vw 7.73vw;

        border-radius: 8vw;
    }
}

@media (max-width: 961px) {
    .prc__wr-product-description p {
        font-size: 4.80vw;
        text-align: center;
    }
}

@media (max-width: 961px) {
    .prc__amount-weight-list {
        margin-top: 5.3vw;
        gap: 9.33vw;

        justify-content: center;
    }
}

@media (max-width: 961px) {
    .prc__wr-amount-weight-icon {
        height: 16vw;
        width: 16vw;
    }
}

@media (max-width: 961px) {
    .prc__wr-amount-weight-text {
        margin-top: 0.8vw;

        font-size: 5.33vw;
    }
}

@media (max-width: 961px) {
    .prc__wr-title-brewing-tea {
        margin-top: 0;
    }
}

@media (max-width: 961px) {
    .prc__title-brewing-tea {
        font-size: 5.33vw;
        text-align: center;
    }
}

@media (max-width: 961px) {
    .prc__method-brewing-list {
        margin-top: 4.27vw;
        gap: 9.33vw;

        justify-content: center;
    }

    .prc__method-brewing-list_top-selection {
        width: 57%;
        margin: 4.27vw auto 0;
        gap: 4.33vw;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

@media (max-width: 961px) {
    .prc__method-brewing-icon {
        height: 16vw;
        width: 16vw;
    }
}

@media (max-width: 961px) {
    .prc__method-brewing-text {
        margin-top: 0.8vw;

        font-size: 5.33vw;
    }
}

@media (max-width: 961px) {
    .star-prod-card {
        width: 3.5vw;
        height: 3.41vw
    }
}

@media (max-width: 961px) {
    .prc__star-top {
        right: 17.69vw;
        bottom: 59.47vw;
    }
    
    .prc__star-bottom {
        right: 11.67vw;
        bottom: 52.93vw;
    }
}


