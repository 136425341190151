.product__container {
margin-top: 50px;
margin-bottom: 0px;
padding-left: 150px;
padding-right: 150px;
} 





.product__image {    
        position: relative;
        color: #FFF;
        text-align: center;
        font-family: Blogger Sans;
        font-size: 2.25rem;
        font-style: normal;
        font-weight: 200;
        line-height: normal;         
        width: 392px;
        height: 550px;
        border-radius: 50px;
        padding-left: 0px;
        padding-right: 0px;      
}

.product-card__header {
color: #FEFEFE;
text-align: center;
font-family: Arsenal;
font-size: 60px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.product__image--wrap {
    box-sizing: border-box;
    position: relative;
    height: 100%;
    width: 100%;
}
   

.product__image--wrap {
    box-sizing: border-box;
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 50px;  
       
}

.product__card--image {
    z-index: 15;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;  
    flex-direction: column;  
    justify-content: center;
    align-items: center;
}

.product__card--image{    
    width: unset;   
}

.product-card .product__image {
    width: 700px;    
    padding-right: 15%;
}

.product-card .catalog__card--animated--img {    
  margin: auto;
  width: 0px;
  scale: 0;
  transition: all 2.8s linear;  
}

.product__image--wrap:hover  .catalog__card--animated--img{               
    width: 560px;  
    scale: 1.5;
    transition: scale 2.8s linear;    
}

.product__description__container {
    position: relative; 
    content: '';
    box-sizing: border-box;
    padding-top: 45px;
    padding-bottom: 45px;
    display: flex;    
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;    
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 50px;    
    background: url(../img/icons/2stars.png), linear-gradient(180deg, #7775B6 0%, #494694 69.79%);   
    background-size: 20% cover;    
    background-position-x: 80%;
    background-position-y: 80%;
    background-repeat: no-repeat;
    z-index: -10;  
}

.product__description--text {
    color: #FFF;
font-family: Blogger Sans;
font-size: 28px;
font-style: normal;
font-weight: 200;
line-height: normal;
}

.product__description__container span {
  margin-top: 10px;
  white-space: nowrap;  
  color: #FFF;
  font-family: Arsenal;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.product-grid {
    display: grid;
    grid-template-columns: 45% 55%;
    grid-template-rows: auto;
    margin-top: 88px;

}

.grid-container > .card {
    /* background-color: rgba(255, 255, 255, 0.8); */
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
  }

  .card__image__section {    
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text__section {    
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .product__type, .product__weight {
    display: flex;    
    flex-direction: column;        
    align-items: center;
    margin-right: 10%;
    color: #FFF;
    font-family: Blogger Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    white-space: nowrap;
  }

  .product__type--temperature>span{
    font-family: Blogger Sans;
  }

  .product__type--temperature>span>sup{
    font-size: 18px;
  }  

  .product__icon-img {

  }

  .product__parameters {
    display: grid;
    grid-template-columns: auto auto auto;
  }

  .parameters__col {
    margin-top: 33px;
  }

  .methods__row {
    display: flex;
    grid-column: 1/5;
    margin-top: 33px;
  }


  .slider-section {
    margin-top: 150px;
  }

  .slider-section h2{
    color: #FFF;
    text-align: center;
    font-family: Arsenal;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media (max-width: 1400px) {
    .product__description--text {
  font-size: 18px;
  }
  
  .product__description__container span {
    margin-top: 10px;    
    font-size: 22px;
  }

  .product__card--image .catalog__card--img {
    width: 300px;
}
 
.methods__row {
  margin-top: 15px;
}

.product__icon-img {
  width: 80px;
  height: 80px;
}

.product-card__header {
  font-size: 48px;
}

.slider-section h2 {
  font-size: 48px;
}

.product__container {
  margin-top: 30px;
}

.product-grid {
  margin-top: 20px;
}

  }