 .header--catalog a {
    margin-bottom: 0px;
    color: white;
}

.catalog__wr-description {
    display: flex;

    justify-content: center;
}

.catalog__description {
    width: 67.55vw;
    padding-left: 15px;
    padding-right: 15px; 
}

.catalog__title {
        color: #FFC300;
        font-family: Arsenal;
        font-size: 3.75rem;
        font-style: normal; 
        font-weight: 200;
        line-height: normal;
        text-align: center;
        transform: translateX(-0.8vw);
}

.catalog__wr-text {
    display: flex;
    gap: 1.35vw;

    flex-direction: column;
    overflow: hidden;

    transition: height 0.3s linear;
}

.catalog__wr-text p {
    height: 100%;
    margin-bottom: 0;

    font-family: Blogger Sans;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 200;

    color: #ffffff;
}

.catalog__read-more {
    display: inline-block;
    margin-top: 1.09vw;

    font-family: Blogger Sans;
    font-size: 0.73vw;
    font-style: normal;
    font-weight: 400;

    color: #ffffff;

    cursor: pointer;
}


.catalog__wr-cards {
    margin-top: 5.05vw;
}

.catalog__tea-pack {
    margin-top: 3.13vw;
}






/* .catalog__description--text {
    color: #FFF;
    font-family: Blogger Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 200;
    line-height: 1.875;   
    height: fit-content;
    overflow: hidden;   
}
.catalog__description--text p{
    line-clamp: 2;
    -webkit-line-clamp: 2;
} */

/* .catalog__container {
    width: 100%;
    max-width: 137.9rem;
    margin-left: auto;
    margin-right: auto;
} */

.catalog {
    margin-top: 24px;
    margin-bottom: 0;
    padding-left: 15%;
    padding-right: 15%;
}

.catalog--pyrapack {
    margin-bottom: 426px;
}

.catalog--pyrapack h1{
    color: white;
}

.catalog--link {
    color: white;
} 

.header--catalog::after {
    content: '';    
    width: 100%;
    height: 100%;
    background-size: cover;    
    background-repeat: no-repeat;
    background-image: url(../img/top-leafs.png);    
    background-position-x: center;
    background-position-y: top;    
    z-index: -10;
    position: absolute;
    top: 0;
}

.catalog-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
}

.catalog__item {
    position: relative;
    overflow: hidden;
    color: #FFF;
    text-align: center;
    font-family: Blogger Sans;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 200;
    line-height: normal;         
    /* width: 20vw;
    height: 26vw;     */
    width: 382px;
    height: 510px;
    /* border-radius: 2.6vw; */
    border-radius: 50px;
    padding-left: 0px;
    padding-right: 0px;    
    margin-bottom: 50px;
    background-image: linear-gradient(180deg, #7775B6 0%, #494694 69.79%);   

}


.product__card {
    box-sizing: border-box;
    position: relative;
    height: 100%;
    width: 100%;
    /* padding-top: 15%;
    padding-bottom: 5%; */
    /* padding-left: 10%;
    padding-right: 10%; */
    border-radius: 50px;     
}

.product__card--image {
    z-index: 15;
    width: 100%;
    height: 100%;
    position: absolute;
    /* bottom: 10%; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;  
    flex-direction: column;  
    justify-content: center;
    align-items: center;
}


.catalog__card--img {
    height: 100%;    
    width: 75%;
    object-fit: scale-down;    
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 25;
}

.product__card--back {
    text-align: center;
    /* border-radius: 2.5vw; */
    border-radius: 50px;
      overflow: hidden;
    
  }
  
  .product__card--back::after {  
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* border-radius: 2.5vw; */
    border-radius: 50px;
    border: 3px solid transparent; 
    border-image-source: linear-gradient(#00BD8D 32.29%, #00392D 100%) border-box;
    background: linear-gradient(180deg, #00BD8D 32.29%, #00392D 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;  
    box-shadow: 0px 0px 0px 0px rgba(8, 43, 34, 0.10), -1px -4px 9px 0px rgba(8, 43, 34, 0.10), -4px -16px 17px 0px rgba(8, 43, 34, 0.09), -9px -37px 23px 0px rgba(8, 43, 34, 0.05), -16px -66px 27px 0px rgba(8, 43, 34, 0.01), -26px -102px 30px 0px rgba(8, 43, 34, 0.00), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);    
  }
  

  .card__container {
    /* padding-left: 1.0rem;
    padding-right: 1.0rem; */
    width: 100%;
    /* max-width: 137.9rem; */
    /* margin: 0 auto; */
    margin-left: auto;
    margin-right: auto;
}


.card__body {
/* height: 510px;
width: 100%; */
/* width: 23.875rem;
height: 31.875rem; */
margin-left: auto;
margin-right: auto;
padding: 0;
}

.grid {
    display: grid;
    /* grid-template-columns: repeat(3, 1fr); */
    grid-template-columns: repeat(auto-fit, minmax(382px, 1fr));
    max-width: 11274px;
    gap: 50px;
    grid-auto-rows: 510px;
}


.product__card-label {    
    z-index: 30;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;       
    height: 25%;
    margin-right: 25px;
    margin-left: 25px;
    transition: 1s;  
    border-radius: 16px;    
    background: linear-gradient(180deg, #587cb4f0 24.47%, rgba(43, 81, 107, 0.00) 100%);
    backdrop-filter: blur(3px);
    box-shadow: 0px 0px 0px 0px rgba(8, 43, 34, 0.10), -1px -4px 9px 0px rgba(8, 43, 34, 0.10), -4px -16px 17px 0px rgba(8, 43, 34, 0.09), -9px -37px 23px 0px rgba(8, 43, 34, 0.05), -16px -66px 27px 0px rgba(8, 43, 34, 0.01), -26px -102px 30px 0px rgba(8, 43, 34, 0.00);
}

.product__card-label {
    display: flex;
    flex-direction: column;
}

.product__card:hover .product__card-label{
    height: 0;
}

.product__card-label_mobile {
    display: none;
}

.product__type {
    font-size: 24px;
}

.catalog__card--animated {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 13;
    width: 100%;  
    padding: 0;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.catalog__card--animated--img {        
    width: 380px; 
    scale: 0;
    transition: scale 1.8s linear;
}

.product__card:hover  .catalog__card--animated--img{        
    /* width: 380px;  */
    scale: 1;
    transition: scale 1.8s linear;    
}

.product__card:hover  .catalog__card--animated--img_2{        
    /* width: 380px;  */
    scale: 1.15 !important;
    transition: scale 1.8s linear;    
}


/* СЛАЙДЕРЫ */

.catalog__slider-container_desctop {
    display: flex;
    margin-top: 5.31vw;

    justify-content: center;
}

.tea-sl__wrapper-catalog {
    width: 84.17vw;
}


.catalog__slider--container_mobile {
    display: none;
}




@media (min-width: 576px) and (max-width: 800px) {
       
    .catalog__item {
        width: 282px;
        height: 377px;
        border-radius: 37px;
        font-size: 1.75rem;
    }

    .catalog__card--img {
        width: 200px;
    }
    .product__card, .product__card--back, .product__card--back::after  {
        border-radius: 37px;
    }

    .grid {
        display: grid;
        /* grid-template-columns: repeat(3, 1fr); */
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        max-width: 11274px;
        gap: 25px;
        grid-auto-rows: 377px;
    }
    

}



@media (max-width: 961px) {
    .catalog__description {
        width: 77.55vw;        
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 7.47vw;
    }
}

@media (max-width: 961px) {
    .catalog__title {
        font-size: 7.47vw;
        margin-bottom: 4vw;
    }
}

@media (max-width: 961px) {
    .catalog__wr-text {
        gap: 4vw;
    }
}

@media (max-width: 961px) {
    .catalog__wr-text p {
        font-size: 3.73vw;
        text-align: center;
    }
}

@media (max-width: 961px) {
    .catalog__read-more {
        font-size: 3.73vw;
        font-weight: 500;
        text-align: center;
    }
}

@media (max-width: 961px) {
    .card__container {
        width: 74.67%;
    }
}

@media(max-width: 961px) {
    .card__body {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width: 961px) {
    .catalog__item {
        width: 100%; 
        height: 104.80vw;
        margin-bottom: 0;

        font-size: 2.5rem;

        border-radius: 50px;
        
        aspect-ratio: 0.8;
    } 
}

@media (max-width: 961px) { 
    .catalog__wr-cards {
        margin-top: 11.47vw;
    }
}

@media (max-width: 961px) {
    .catalog__card--img {
        height: auto;
        width: 49.60vw !important;
        margin-top: 15.2vw;
    }

    .catalog__card--img_2 {
        width: 42.6vw !important;
        margin-top: 12.2vw;
    }

    .catalog__card--img_4 {
        width: 53.33vw !important;
        margin-top: 30vw;
    }

    .catalog__card--img_6 {
        width: 54vw !important;
        margin-top: 14.2vw;
    }

    .catalog__card--img--width {
        width: 70.93vw !important;
        margin-top: 12.2vw;
    }
}

@media (max-width: 961px) {
    .product__card, .product__card--back, .product__card--back::after  {
        border-radius: 50px;
    }
}

@media (max-width: 961px) {
    .grid {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 961px) {
    .product__card-label_desctop {
        display: none;
    }

    .product__card-label_mobile {
        display: flex;

        flex-direction: column;
    }

    .product__card-label {
        bottom: -22px;

        height: 36%;
        margin-right: 3.73vw;
        margin-left: 3.73vw;
        padding-top: 8.53vw;

        font-size: 6.40vw;
    }
}

@media (max-width: 961px) {
    .catalog__slider--container_mobile {
        display: block;
        margin-top: 21.33vw;
        margin-bottom: 26vw;
    }
}

@media (max-width: 961px) {
    .catalog__slider-container_desctop {
        display: none;
    }
}




