.wrapper-page-about-tea {
  background: no-repeat url(../img/background-more-about-tea.png);
  background-size: cover;
  background-position: center bottom;
}

.about-tea__wr-articles {
  display: flex;
  margin-top: 1.56vw;
  margin-bottom: 19.79vw;
  gap: 4.17vw;

  flex-direction: column;
  align-items: center;
}

.about-tea__article {
  display: flex;
  gap: 2.71vw;
}

.about-tea__article-img {
  width: 34.27vw;
  min-height: 20.83vw;

  border-radius: 2.60vw;
  box-shadow: 0px 0px 17px rgb(0 25 10 / 80%);
}

.about-tea__article-img_milk-oolong {
  background: no-repeat url(../img/content/catalog-article-img_card-milk-oolong.webp);
  background-size: cover;
  background-position: center;
}

.about-tea__article-img_non-tea {
  background: no-repeat url(../img/content/catalog-article-img_card-non-tea.webp);
  background-size: cover;
  background-position: center;
}

.about-tea__article-img_staying-green {
  background: no-repeat url(../img/content/catalog-article-img_card-staying-green.webp);
  background-size: cover;
  background-position: center;
}

.about-tea__article-descr {
  display: flex;
  width: 47.19vw;

  flex-direction: column;
}

.about-tea__article-title {
  margin-bottom: 0;

  font-family: Arsenal;
  font-size: 2.34vw;
  font-style: normal;
  font-weight: 400;

  color: #F5C726;
}

.about-tea__article-wr-text {
  margin-top: 1.3vw;
}

.about-tea__article-wr-text p {
  font-family: Arsenal;
  font-size: 1.06vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;

  color: #ffffff;
}

.about-tea__article-wr-link {
  display: flex;

  flex-grow: 1;
  align-items: flex-end;
}

.about-tea__article-link {
  width: 15.68vw;
  height: 3.65vw;
}

.about-tea__article-link span {
  background: #45CFAC !important;
}

 

@media(max-width: 961px) {
  .about-tea__wr-articles {
    margin-top: 10.67vw;
    margin-bottom: 74.67vw;
    gap: 13.33vw;
  }
}

@media(max-width: 961px) {
  .about-tea__article {
    gap: 6.40vw;

    flex-direction: column;
  }
}

@media(max-width: 961px) {
  .about-tea__article-img {
    width: 74.67vw;
    height: 88vw;
  
    border-radius: 8vw;
  }
}

@media(max-width: 961px) {
  .about-tea__article-descr {
    width: 74.67vw;
  }
}

@media(max-width: 961px) {
  .about-tea__article-title {
    font-size: 7.47vw;
    text-align: center;
  }
}

@media(max-width: 961px) {
  .about-tea__article-wr-text {
    margin-top: 2.67vw;
  }
}

@media(max-width: 961px) {
  .about-tea__article-wr-text p {
    font-size: 4.80vw;
    text-align: center;
  }
}

@media(max-width: 961px) {
  .about-tea__article-link {
    width: 100%;
    height: 11.73vw;
  }
}




/* .about-tea {
    height: max-content;
    margin-top: 150px;
    margin-bottom: 150px;
    margin-left: 150px;
    margin-right: 150px;
  }
  
  
.about-tea {
    display: grid;
    grid-template-columns: 100%;
    gap: 50px;  
    padding-bottom: 380px;
  }
  
  .article__row {
    display: grid;
    grid-template-columns: 658px auto;
    gap: 52px;
  }

  .article__row .promo__button {
    width: 300px;
    height: 70px;

  }
  
  .article__image {
    overflow: hidden;
    border-radius: 50px
  }
  
  .article--img {
    object-fit: cover;   
    height: 100%;   
  }
  
  .article__text--col {
    position: relative;
    padding-top: 0;
    display: grid;
    align-content: center;
  }

  
  .article__text--content {  
  color: #FFF;
  font-family: Blogger Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: 125%;  
  width: 100%; 
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  }
  
  .article__text--col h2{
  position: absolute;
  top: -5%;
  left: 0;
  margin: 0;
  color: #F5C726;
  font-family: Arsenal;
  font-size: 60px;
  font-weight: 400;
  }
  

  @media (max-width: 1400px) {

    .article__text--col h2{
      font-size: 48px;
    }

    .article__row {
      display: grid;
      grid-template-columns: 45% auto;
      gap: 40px;
  }

  }
  
  .article__button--wrap {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .about-tea-article {    
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;    
    grid-row-gap: 50px;
    justify-items: center;
    padding-left: 20%;
    padding-right: 20%;
    margin-top: 65px;
    margin-bottom: 175px;
  }
  
  .article__image--wrap {
    width: 100%;
    height: 100%;
  }

  .article--image {
      width: 100%;
      object-fit: cover;
      border-radius: 50px;
      max-height: 470px;
    }


    .article--text {
      color: #FFF;
      font-family: Blogger Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 200;
      line-height: 125%;   
    }

    article-media {
      width: 100%;
      height: 100%;
    }

    .article-media-frame {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .article--media {
      width: 100%;
      max-height: 630px;      
      object-fit: cover;
      border-radius: 50px;
    } */