@font-face {
    font-family: Blogger Sans;
    src: url(../fonts/BloggerSans/Blogger_Sans-Light.otf);
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Blogger Sans;
    src: url(../fonts/BloggerSans/Blogger_Sans.otf);
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Blogger Sans;
    src: url(../fonts/BloggerSans/Blogger_Sans-Bold.otf);
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Arsenal;
    src: url(../fonts/Arsenal/Arsenal-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: Arsenal;
    src: url(../fonts/Arsenal/Arsenal-Bold.ttf);
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SnellRoundhand';
    src: url(../fonts/SnellRoundhand/SNELLROUNDHAND_BOLD.OTF);
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}