@media (max-width: 961px) {
    .tea-sl-swiper_mobile_top-selection {
        height: 89.60vw;
    }
}

@media (max-width: 961px) {
    .tea-sl-m__slide {
        height: 89.6vw;

        transition: height 0.3s ease;
        align-self: center;
    } 

    .tea-sl-m__slide.swiper-slide-prev {
        height: 80.6vw;
    }

    .tea-sl-m__slide.swiper-slide-next {
        height: 80.6vw;
    }
}

@media (max-width: 961px) {
    .tea-sl-m__slide_dellipack-tea-pot {
        height: 64.6vw;

        transition: height 0.3s ease;
        align-self: center;
    } 

    .tea-sl-m__slide_dellipack-tea-pot.swiper-slide-prev {
        height: 56.6vw;
    }

    .tea-sl-m__slide_dellipack-tea-pot.swiper-slide-next {
        height: 56.6vw;
    }
}

@media (max-width: 961px) {
    .tea-sl-m__slide .tea-sl-m__slide-img {
        transition: all 0.3s ease;
    }
}

@media (max-width: 961px) {
    .tea-sl-m__slide-img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center;
    }
}

@media (max-width: 961px) {
    .tea-sl-m__slide-link {
        position: absolute;
        top: 0;
        left: 0;

        height: 100%;
        width: 100%;
    }
}

