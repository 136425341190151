.cards__container {
  display: flex;

  flex-direction: column;
  align-items: center;
}

.contacts__wr-cards-address {
  position: relative;

  display: flex;
  width: 83.85vw;
}

.contacts-flover--left {
  position: absolute;
  top: -12%;
  left: -4.6%;

  height: 10.13vw;
  width: 10.13vw;

  background: no-repeat url(../img/icons/cont-tea-left.png);
  background-position: center;
  background-size: contain;

  z-index: 10;
}

.contacts-flover--left_mobile {
  display: none;
}


.contacts-flover--left-img {
  height: 100%;
  width: 100%;

  object-fit: contain;
  object-position: center;
}

.contacts {
  margin-top: 3vw;
  margin-bottom: 240px;
}

.card--container {
  position: relative;

  display: flex;
  width: 50%;
  padding: 15px;
  box-sizing: border-box;

  justify-content: center;

  z-index: 9;
}

.card--container--left {
  position: relative;
}

.contacts__card--form {
  display: none;
  width: 98%;
  height: 98%;
  border-radius: 50px;
  position: absolute;
  box-sizing: border-box;
  background: linear-gradient(180deg, #7775b6 0%, #494694 69.79%);
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  z-index: 1;
}

.was-validated .form-control:invalid {
  background-image: none;
  color: #ff0000;
  border: 1px solid #ff0000;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none;
  border: none;
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  background: #ffa1a1;
  border: 1px solid #ff0000;
}

.card__visible {
  display: flex;
}

.close-form {
  position: absolute;
  right: 25px;
  top: 25px;

  font-size: 28px;

  color: #fff;

  cursor: pointer;
}

.close-form-icon {
  width: 30px;
  height: 30px;
  filter: none;
  -webkit-filter: none;
  transition: filter 0.15s ease;
}

.close-form-icon:hover {
  filter: drop-shadow(1px 1px 3px rgba(255, 255, 255, 0.7));
  -webkit-filter: drop-shadow(1px 1px 3px rgb(255, 255, 255));
  transition: filter 0.15s ease;
}

.reg__group {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.registry__input--field {
  border-radius: 25px;
  height: 50px;
  border: 1px solid rgba(255, 255, 255, 0.72);
  background: #ebfffa;
  color: #7775b6;
  font-family: "Arsenal";
}

.reg-label {
  color: #d5d5d5;
  font-family: "Arsenal";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.form-check {
  width: 75%;
}

.form-check-label {
  color: #d5d5d5;
  font-family: "Arsenal";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.form-grid {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  width: 100%;
  height: 100%;
}

.input__section {
  text-align: left;
}

.registry__input--text {
  height: 200px;
  width: 100%;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.72);
  background: #ebfffa;
}

.card1 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 4;
  height: fit-content;
}

.card2 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 4;

  align-items: flex-start !important;
}

.card3 {
  padding-top: 25px;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
}

.card4 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
  align-self: end;
}

.button--submit {
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 50px;
  border: none;
  color: #fff;
  font-family: Arsenal;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  background: #7775b6;
  cursor: pointer;
  box-shadow: none;
  transition: box-shadow 0.3s ease;
}

.button--submit:hover {
  box-shadow: 0px 0px 6px 2px rgba(255, 255, 255, 0.685);
  transition: box-shadow 0.3s ease;
}

.button--submit::after {
  content: "";
  position: absolute;
  z-index: -2;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  border-radius: 50px;
  background: linear-gradient(180deg, #00bd8d 32.29%, #00392d 100%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.27), rgba(255, 255, 255, 0.27));
}


.contacts__wr-form-sended-desc {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: none; 

  border-radius: 2.60vw;
  background: linear-gradient(180deg, #00bd8d 32.29%, #00392d 100%),
  linear-gradient(0deg, rgba(255, 255, 255, 0.27), rgba(255, 255, 255, 0.27));
  box-shadow: 0px 0px 0px 0px rgba(0, 25, 19, 0.10),
    13px 13px 41px 0px rgba(0, 25, 19, 0.10),
      54px 52px 75px 0px rgba(0, 25, 19, 0.09),
        121px 118px 101px 0px rgba(0, 25, 19, 0.05),
          215px 209px 120px 0px rgba(0, 25, 19, 0.01),
            336px 327px 131px 0px rgba(0, 25, 19, 0.00);

  align-items: center;
  justify-content: center;
}

.contacts__wr-form-sended-desc_active {
  display: flex;
}

.contacts__wr-form-sended-content {
  display: flex;
  height: 98.8%;
  width: 99.3%;
  margin-top: 0.05vw;

  border-radius: 2.40vw;
  background: linear-gradient(180deg, #7775B6 0%, #494694 69.79%);

  flex-direction: column;
  align-items: center;

}

.contacts__wr-form-sended-close {
  display: flex;
  width: 94%;
  margin-top: 1.30vw;

  justify-content: flex-end;
}

.contacts__form-sended-close-icon {
  height: 1.56vw;
  width: 1.56vw;

  cursor: pointer;
}

.contacts__wr-form-sended-icon {
  display: flex;
  margin-top: 3.18vw;

  justify-content: center;
}

.contacts__form-sended-icon {
  width: 6.77vw;
  height: 6.77vw;
}

.contacts__wr-form-sended-text {
  display: flex;
  margin-top: 1vw;

  font-family: Blogger Sans;
  font-size: 1.67vw;
  font-style: normal;
  font-weight: 400;

  color: #FFF;

  flex-direction: column;
  align-items: center;
}

.contacts__wr-form-sended-text span {
  line-height: 1.1;
}

.contacts__wr-form-sended-start {
  position: absolute;

  width: 1.77vw;
  height: 1.88vw;
}

.contacts__wr-form-sended-start-1 {
  top: 2.03vw;
  left: 3.75vw;
}

.contacts__wr-form-sended-start-2 {
  bottom: 6.72vw;
  right: 6.15vw;
}

.contacts__wr-form-sended-start-3 {
  bottom: 5.36vw;
  right: 4.38vw;
}

.contacts__card {
  position: relative;

  display: flex;
  height: 100%;
  width: 100%;
  margin-bottom: 50px;
  box-sizing: border-box;
  
  text-align: center;
  
  border-radius: 50px;
  background: url(../img/icons/2stars-contacts-left.png),
    linear-gradient(180deg, #7775b6 0%, #494694 69.79%);
  background-size: 20% cover;
  background-position-x: 90%;
  background-position-y: 18%;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 0px 0px rgba(0, 25, 19, 0.1),
    13px 13px 41px 0px rgba(0, 25, 19, 0.1),
    54px 52px 75px 0px rgba(0, 25, 19, 0.09),
    121px 118px 101px 0px rgba(0, 25, 19, 0.05),
    215px 209px 120px 0px rgba(0, 25, 19, 0.01),
    336px 327px 131px 0px rgba(0, 25, 19, 0);

  flex-direction: column;
  align-content: center;
  justify-content: center;

  z-index: 1;
}

.contacts__card-wr-text {
  padding-left: 3.23vw;
  padding-right: 0.78vw;
  margin-bottom: 2.6vw;
}

.contacts__card--business {
  background: url(../img/icons/2stars-contacts-left.png),
    linear-gradient(180deg, #7775b6 0%, #494694 69.79%);
  background-size: 20% cover;
  background-position-x: 85%;
  background-position-y: 77%;
  background-repeat: no-repeat;
}

.contacts__card .promo__button {
  width: 50%;
  height: 70px;
  font-size: 28px;
}

.contacts__card--text {
  margin-bottom: 1.56vw;
  
  font-family: Blogger Sans;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;

  color: #fff;
}

.contatcts__buton {
  position: relative;

  display: flex;
  box-sizing: border-box;
  width: 50%;
  height: 3.65vw;

  border-radius: 2.60vw;
  border: none;
  color: #fff;
  background: #7775b6;
  box-shadow: none;

  font-family: Arsenal;
  font-size: 1.46vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  transition: box-shadow 0.3s ease;
}

.contatcts__buton::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  border-radius: 50px;
  background: linear-gradient(180deg, #00bd8d 32.29%, #00392d 100%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.27), rgba(255, 255, 255, 0.27));
}

.contatcts__buton:hover,
.contatcts__buton--round:hover {
  box-shadow: 0px 0px 6px 2px rgba(255, 255, 255, 0.685);
  transition: box-shadow 0.3s ease;
}

.contatcts__buton--round {
  display: flex;
  width: 3.65vw;
  height: 3.65vw;
  margin-left: 28px;

  box-shadow: none;

  justify-content: center;
  align-items: center;

  transition: box-shadow 0.3s ease;
}

.contacts__wr-icon-button-callback {
  height: 3.65vw;
  width: 3.65vw;
}

.contacts__icon-button-callback {
  display: block;
  height: 100%;
}

.contatcts__buton--icon {
  margin: auto;
  padding: 10px;
}

.phone__wrap {
  width: 100%;
  
  font-family: Blogger Sans;
  font-size: 1.46vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;

  color: #fff;
}

.phone__wrap a {
  text-decoration: none;
  font-weight: 200;
}

ymaps.ymaps-2-1-79-map-copyrights-promo {
  margin-left: 1vw;
}

ymaps.ymaps-2-1-79-copyright__wrap {
  margin-right: 1vw;
}

.contacts__card h1 {
  margin-bottom: 0.78vw;

  font-family: Arsenal;
  font-size: 2.34vw;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-align: center;

  color: #ffc300;
}

.contacts__card a {
  color: white;
}


.baloon__content {
  font-family: Arsenal;
}

.map_baloon {
  border-radius: 5px;
}

.contacts__card-map {
  display: flex;
  width: 45.57vw;
  height: 27.81vw;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 2px;
  box-sizing: border-box;
  
  background: linear-gradient(180deg, #00bd8d, #00392d);
  border-radius: 2.60vw;

  justify-content: center;
  align-items: center;
}

.contacts__map-image {
  width: 100%;
  height: 100%;

  background: no-repeat url(../img/content/map.png);
  background-size: cover;
  background-position: center;

  border-radius: 2.60vw;
}

.contacts__card--back {
  text-align: center;
  border-radius: 50px;
  overflow: hidden;
}

.contacts__card--back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  /* border-radius: 2.5vw; */
  border-radius: 50px;
  border: 3px solid transparent;
  border-image-source: linear-gradient(#00bd8d 32.29%, #00392d 100%) border-box;
  background: linear-gradient(180deg, #00bd8d 32.29%, #00392d 100%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  box-shadow: 0px 0px 0px 0px rgba(8, 43, 34, 0.1),
    -1px -4px 9px 0px rgba(8, 43, 34, 0.1),
    -4px -16px 17px 0px rgba(8, 43, 34, 0.09),
    -9px -37px 23px 0px rgba(8, 43, 34, 0.05),
    -16px -66px 27px 0px rgba(8, 43, 34, 0.01),
    -26px -102px 30px 0px rgba(8, 43, 34, 0),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.contacts-flover--right {
  position: absolute;
  bottom: -18%;
  right: -14%;

  width: 13.39vw;
  height: 14.64vw;

  background: no-repeat url('../img/icons/cont-tea-right.png');
  background-size: contain;
  background-position: center;

  z-index: 10;
}

.buttons__row {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* .select--city {
  background-image: url(../img/icons/select-arrow.png);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
} */

.select--city::after {
  /* content: url(../img/icons/select-arrow.png);            */
  background: pink;
}

.contacts__wr-card-address-item {
  display: none;
}



@media (max-width: 961px) {
  .contacts__main {
    margin-top: 10.93vw;
  }
}

@media (max-width: 961px) {
  .breadcrumbs__container {
    display: none;
  }
}

@media (max-width: 961px) {
  .contacts {
    padding-left: 5.33vw;
    padding-right: 5.33vw;
    margin-bottom: 26.40vw;
  }
}

@media (max-width: 961px) {
  .contacts__wr-cards-address {
    width: 100%;
  }
}

@media (max-width: 961px) {
  .card--container_desctop {
    display: none;
  }
}

@media (max-width: 961px) {
  .contacts__cards-address-list_mobile {
    width: 100%;
    padding-left: 0;
  }
}

@media (max-width: 961px) {
  .contacts__wr-card-address-item {
    position: relative;

    display: none;
    padding-bottom: 12.53vw;
  }

  .contacts__wr-card-address-item_left {
    background: no-repeat url(../img/contacts-fon-cards-address.webp);
    background-size: 100% 100%;
    background-position: center;
  }

  .contacts__wr-card-address-item_right {
    background: no-repeat url(../img/contacts-fon-cards-addres2.webp);
    background-size: 100% 100%;
    background-position: center;
  }

  .contacts__wr-card-address-item-form {
    background: no-repeat url(../img/contacts-fon-cards-address-form.webp);
    background-size: 100% 100%;
    background-position: center;
  }

  .contacts__wr-card-address-item-sended {
    background: no-repeat url(../img/contacts-fon-cards-sended.webp);
    background-size: 100% 100%;
    background-position: center;
  }

  .contacts__wr-card-address-item_active {
    display: block;
  }
}

@media (max-width: 961px) {
  .contacts-flover--left_desctop {
    display: none;
  }

  .contacts-flover--left_mobile {
    top: -7%;
    left: -5.1%;

    display: block;
    width: 16.53vw;
    height: 16.27vw;

    transform: rotate(24deg);

    z-index: 0;
  }
}

@media (max-width: 961px) {
  .contacts__wr-card-address-header {
    display: flex;
  }
}

@media (max-width: 961px) {
  .contacts__wr-card-address-title {
    display: flex;
    width: 50%;

    align-items: center;
    justify-content: center;
  }

  .contacts__wr-card-address-title h2 {
    margin-top: 4.53vw;
    margin-bottom: 2.67vw;

    font-family: Arsenal;
    font-size: 5.07vw;
    font-style: normal;
    font-weight: 400;

    color: #FFC300;
  }

  .contacts__wr-card-address-title_active h2::after {
    content: "";

    display: block;
    width: 100%;

    height: 1px;

    background-color: #FFC300;
  }
}

@media (max-width: 961px) {
  .contacts__wr-card-address-text {
    display: flex;
    padding-left: 7.73vw;
    margin-bottom: 5vw;
    margin-top: 2.1vw;
    gap: 3.8vw;

    flex-direction: column;
  }
}

@media (max-width: 961px) {
  .contacts__wr-card-address-text p {
    display: flex;
    margin-bottom: 0;

    font-family: Blogger Sans;
    font-size: 4.80vw;
    font-style: normal;
    font-weight: 400;

    color: #ffffff;

    flex-direction: column;
  }
}

@media (max-width: 961px) {
  .contacts__card-address-tel-link {
    font-family: Blogger Sans;
    font-size: 4.80vw;
    font-style: normal;
    font-weight: 400;

    color: #ffffff;
  }
}

@media (max-width: 961px) {
  .contacts__wr-card-buttons {
    display: flex;
    gap: 5.07vw;

    justify-content: center;
  }
}

@media (max-width: 961px) {
  .contacts__wr-link-address-redirect {
    display: flex;
    height: 11.73vw;
    width: 57.87vw;

    border-radius: 13.33vw;
    background: linear-gradient(180deg, #00bd8d 32.29%, #00392d 100%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.27), rgba(255, 255, 255, 0.27));

    align-items: center;
    justify-content: center;
  }

  .contacts__wr-link-address-redirect_right-card {
    width: 74.67vw;
  }
}

@media (max-width: 961px) {
  .contacts__link {
    display: flex;

    height: 10.67vw;
    width: 56.80vw;

    font-family: Arsenal;
    font-size: 4.27vw;
    font-style: normal;
    font-weight: 400;

    color: #FFF;
    border-radius: 13.33vw;
    background: #7A78B1;

    align-items: center;
    justify-content: center;
  }

  .contacts__link_right-card {
    width: 73.60vw;
  }
}

@media (max-width: 961px) {
  .contacts-flover--right_mobile {
    bottom: -6%;
    right: -6.2%;

    width: 22vw;
    height: 22vw;
  }
}

@media (max-width: 961px) {
  .contacts__button-callback {
    display: flex;
    height: 11.73vw;
    width: 11.73vw;

    border-radius: 50%;
    background: linear-gradient(180deg, #00bd8d 32.29%, #00392d 100%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.27), rgba(255, 255, 255, 0.27));

    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 961px) {
  .contacts__wr-icon-button-callback {
    display: flex;

    height: 10.67vw;
    width: 10.67vw;

    border-radius: 13.33vw;
    background: #7A78B1;

    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 961px) {
  .contacts-flover--left-form_mobile {
    top: -3.9%;
    left: -5.1%;

    display: block;
    width: 16.53vw;
    height: 16.27vw;

    transform: rotate(24deg);

    z-index: -1;
  }
}

@media (max-width: 961px) {
  .contacts__wr-card-address-form {
    display: flex;
    margin-top: 6.33vw;

    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 961px) {
  .contacts__wr-card-address-form-close {
    display: flex;
    width: 74.67vw;

    justify-content: flex-end;
  }
}

@media (max-width: 961px) {
  .contacts__wr-card-address-close-icon {
    height: 6.13vw;
    width: 6.13vw;
  }
}

@media (max-width: 961px) {
  .contacts__card-address-form {
    width: 74.67vw;
    margin-top: 1.33vw;
  }
}

@media (max-width: 961px) {
  .contacts__wr-address-input-list {
    display: flex;
    padding-left: 0;
    gap: 1.33vw;

    flex-direction: column;
  }
}

@media (max-width: 961px) {
  .contacts__wr-address-input-item {
    display: flex;

    flex-direction: column;
  }
}

@media (max-width: 961px) {
  .contacts__address-form-label {
    font-family: WMF Rotis;
    font-size: 4.27vw;
    font-style: normal;
    font-weight: 400;

    color: #D5D5D5;
  }
}

@media (max-width: 961px) {
  .contacts__address-form-input {
    width: 100%;
    height: 10.67vw;
    padding-left: 4.27vw;

    font-family: WMF Rotis;
    font-size: 3.20vw;
    font-style: normal;
    font-weight: 400;

    border: 0;
    border-radius: 6.67vw;
    background: #EBFFFA;
    color: #7775B6;
  }

  .contacts__address-form-input:focus {
    border: 0;
    outline: 0;
  }

  .contacts__form-input_no-valid {
    border: 1px solid #ff0000;
    color: #ff0000;
  }
}

@media (max-width: 961px) {
  .contacts__wr-address-form-policy {
    display: flex;
    margin-top: 2.67vw;
  }
}

@media (max-width: 961px) {
  .contacts__address-form-checkbox {
      display: none;
  }
}

@media (max-width: 961px) {
  .contacts__address-form-checkbox-label {
    display: flex;
    gap: 2.13vw;

    font-family: WMF Rotis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    color: #D5D5D5;
  }

  .contacts__address-form-checkbox-label::before {
    content: "";

    display: block;
    height: 4.27vw;
    width: 4.27vw;
    min-width: 4.27vw;
    margin-top: 0.7vw;

    border-radius: 1vw;
    background-color: #EBFFFA;
  }

  .contacts__address-form-checkbox:checked + .contacts__address-form-checkbox-label::before {
    border: 1px solid #fff;
    background: #EBFFFA;
    background-image: url('../img/icons/check-form-modile.svg');
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
  }

  .contacts__form-input_no-valid + .contacts__address-form-checkbox-label::before {
    border: 1px solid #ff0000;
    background: #FFA1A1;
  } 

  .contacts__form-input_no-valid + .contacts__address-form-checkbox-label {
    color: #ff0000;
  }
}

@media (max-width: 961px) {
  .contacts__wr-address-form-text {
    margin-top: 2.67vw;
  }
}

@media (max-width: 961px) {
  .contacts__address-form-text {
    max-width: 100%;
    min-width: 100%;
    max-height: 53.33vw;
    min-height: 53.33vw;
    padding: 4.27vw;

    border-radius: 6.67vw;
    border: 0;
    background: #EBFFFA;
    background-image: none;

    resize: none;
  }

  .contacts__address-form-text:focus {
    border: 0;
    outline: 0;
  }
}

@media (max-width: 961px) {
  .contacts__wr-address-button-submit {
    margin-top: 2.67vw;
  }
}

@media (max-width: 961px) {
  .contacts__address-button-submit {
    display: flex;
    height: 11.73vw;
    width: 100%;

    border: 0;
    border-radius: 13.33vw;
    background: linear-gradient(180deg, #00bd8d 32.29%, #00392d 100%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.27), rgba(255, 255, 255, 0.27));

    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 961px) {
  .contacts__address-button-submit_deco {
    display: flex;

    height: 10.67vw;
    width: 73.60vw;

    font-family: Arsenal;
    font-size: 4.27vw;
    font-style: normal;
    font-weight: 400;

    color: #FFF;
    border-radius: 13.33vw;
    background: #7A78B1;

    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 961px) {
  .contacts__wr-card-address-item-sended {
    padding-bottom: 18vw;
  }
}

@media (max-width: 961px) {
  .contacts-flover--left-sended_mobile {
    top: -6.5%;
    left: -5.1%;

    display: block;
    width: 16.53vw;
    height: 16.27vw;

    transform: rotate(24deg);

    z-index: 0;
  }
}

@media (max-width: 961px) {
  .contacts__wr-form-sended-content {
    border-radius: 0;
    background: none;
  }
}

@media (max-width: 961px) {
  .contacts__wr-form-sended-close {
    width: 83%;
    margin-top: 4vw;
  }
}

@media (max-width: 961px) {
  .contacts__form-sended-close-icon {
    width: 6.13vw;
    height: 6.13vw;
  }
}

@media (max-width: 961px) {
  .contacts__wr-form-sended-icon {
    margin-top: 10vw;
  }
}

@media (max-width: 961px) {
  .contacts__form-sended-icon {
    width: 26.93vw;
    height: 26.40vw;
  }
}

@media (max-width: 961px) {
  .contacts__wr-form-sended-text {
    margin-top: 9.07vw;

    font-size: 6.40vw;
  }
}

@media (max-width: 961px) {
  .contacts__wr-form-sended-start {
    width: 4.27vw;
    height: 4.53vw;
  }

  .contacts__wr-form-sended-start-1 {
    display: none;
  }

  .contacts__wr-form-sended-start-2 {
    bottom: 46.93vw;
    right: 10.40vw;
  }

  .contacts__wr-form-sended-start-3 {
    bottom: 42.67vw;
    right: 3.73vw;
  }
}

@media (max-width: 961px) {
  .contacts__card {
    position: relative;
    width: 35rem;
  }
}

@media (max-width: 961px) {
  .contacts__card-map {
    height: 104.80vw;
    width: 100%;
    margin-bottom: 0;

    border-radius: 8vw;
  }
}

@media (max-width: 961px) {
  .contacts__map-image {
    border-radius: 8vw;
    background-size: auto 100%;
    background-position: left top;
  }
}

@media (max-width: 961px) {
  .card--container-map {
    width: 100%;

  }

  .card--container {
    padding: 0;
  }
}

@media (max-width: 961px) {
  .contacts__form {
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media (max-width: 961px) {
  .phone__wrap span {
    margin-bottom: 38px;
    font-weight: 400;
  }
}

@media (max-width: 961px) {
  .cards__container {
    flex-direction: column;
  }
}

@media (max-width: 961px) {
  .contacts__card--text {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 200;
  }
}

