.same-packs__wrapper {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font-style: normal;
    font-weight: 400;
 
    list-style-type: none;
}

.same-packs__wrapper a {
    display: inline-block;
    width: 100%;
    height: 100%;

    text-decoration: none;

    color: #000;
}

.same-packs__wrapper {
    display: flex;

    flex-direction: column;
    align-items: center;
}

.same-packs__wr-title {
    display: flex;
    width: 100%;
    margin-bottom: 5.05vw;

    justify-content: center;
}

.same-packs__title {
    font-family: Arsenal;
    font-size: 2.34vw;
    font-weight: 400;

    color: #ffffff;
}

.same-packs__list {
    display: flex;
    max-width: 67.55vw;
    width: 100%;
    padding-left: 0;

    flex-wrap: wrap;
}

.same-packs__item {
    display: flex;
    width: 33.333%;
    justify-content: center;
}

.same-packs__item:nth-child(n + 4) {
    margin-top: 2.60vw;
}

.same-packs__item_top-selection-shu-puer-bing-cha .same-packs__wr-pack-img > img {
    width: 130%;
}

.same-packs__wr-link {
    position: relative;

    display: flex;
    width: 19.90vw;
    min-height: 26.56vw;
    padding: 2px;

    background: linear-gradient(180deg, #00BD8D, #00392D);
    border-radius: 2.60vw;

    /* overflow: hidden; */
}

.same-packs__link {
    position: absolute;

    display: flex !important;

    border-radius: 2.57vw;

    overflow: hidden; 
    justify-content: center;
}

.same-packs__link_dellipack {
    background: no-repeat url(../img/card-background-dellipack.webp), linear-gradient(180deg, #7775B6, #494694);
    background-size: cover;
    background-position: center;
} 

.same-packs__link_pyramid {
    background: no-repeat url(../img/card-background-pyramid.webp), linear-gradient(180deg, #7775B6, #494694);
    background-size: cover;
    background-position: center;
}

.same-packs__link_tea-pot {
    background: no-repeat url(../img/card-background-tea-pot.webp), linear-gradient(180deg, #7775B6, #494694);
    background-size: cover;
    background-position: center;
}

.same-packs__link_leaf {
    background: no-repeat url(../img/card-background-leaf.webp), linear-gradient(180deg, #7775B6, #494694);
    background-size: cover;
    background-position: center;
}

.same-packs__link_top-selection {
    background: no-repeat url(../img/card-background-leaf.webp), linear-gradient(180deg, #7775B6, #494694);
    background-size: cover;
    background-position: center;
}

.same-packs__link_accessories {
    background: no-repeat url(../img/card-background-accessories.webp), linear-gradient(180deg, #7775B6, #494694);
    background-size: cover;
    background-position: center;
}
/*  */
/* card-background-pyramid.png */

.same-packs__wr-animate-img { 
    position: absolute;
    top: -8%;

    display: flex;
    height: 100%;
    width: 100%;


    justify-content: center;
    align-items: center;
    transform: scale(50%, 50%);

    transition: transform 1s ease-out;

    z-index: 1;
    pointer-events: none;
}

.same-packs__wr-animate-img_dellipack {
    top: -4%;
}

.same-packs__wr-animate-img_leaf {
    top: -5%;
}

.same-packs__wr-animate-img_top-selection {
    top: -4%;
}

.same-packs__wr-animate-img_accessories {
    top: 4%;

    transform: scale(40%, 40%);
}

.same-packs__animate-img {
    width: 100%;
    height: 100%;

    object-fit: contain;
    object-position: center;
}

.same-packs__wr-pack-img {
    position: absolute;

    display: flex;
    height: 100%;
    width: 94%;

    align-items: center;
    justify-content: center;

    z-index: 2;
} 

/* Индивидуальные размеры */

.same-packs__wr-pack-img_pyramid {
    width: 13.59vw;
}

.same-packs__wr-pack-img_dellipack {
    width: 11.15vw;
}

.same-packs__wr-pack-img_tea-pot {
    width: 14.84vw;
    margin-top: -1vw;
}

.same-packs__wr-pack-img_leaf {
    margin-top: -0.4vw;
    width: 13.15vw;
}

.same-packs__wr-pack-img_top-selection {
    margin-top: -0.4vw;
    width: 11.5vw;
}

/* Конец индивидуальные размеры  */

.same-packs__pack-img {
    object-fit: contain;
    object-position: center;
    height: 100%;
    width: 100%;
}

.same-packs__wr-description {
    position: absolute;
    bottom: -3.5%;

    display: flex;
    height: 7.9vw;
    width: 18.28vw;

    background: linear-gradient(180deg, rgba(88, 124, 180, 0.79) 33.85%, rgba(43, 81, 107, 0.00) 100%);
    border-radius: 0.83vw;
    
    flex-direction: column;
    align-items: center;

    transition: bottom 0.5s ease-out;

    z-index: 3;
}

.same-packs__wr-description p {
    display: flex;
    margin-top: 0.5vw;
    margin-bottom: 0;

    font-family: Blogger Sans;
    font-weight: 400;
    line-height: 1.1;

    color: #ffffff;

    flex-direction: column;
    align-items: center;
}

.same-packs__wr-description p:first-child {
    font-size: 1.8vw;
}

.same-packs__wr-description p:last-child {
    font-size: 1.25vw;
}

@media (min-width: 769px) {
    .same-packs__link:hover .same-packs__wr-description {
        bottom: -31.3%;
    }

    .same-packs__wr-animate-img:hover + .same-packs__link .same-packs__wr-description {
        bottom: -31.3%;
    }
}
 
@media (min-width: 769px) {
    .same-packs__wr-link:hover .same-packs__wr-animate-img {
        transform: scale(125%, 125%);
    }
    /* сделано индивидуально анимация по просьбе руководителя */
    .same-packs__item_dellipack-horeka-earl-grey .same-packs__wr-link:hover .same-packs__wr-animate-img {
        transform: scale(110%, 110%);
    }
}






@media (max-width: 961px) {
    .same-packs__wrapper {
        margin-top: 3vw;
    }
}

@media (max-width: 961px) {
    .same-packs__wr-title {
        width: 54.67vw;
        margin-bottom: 11.47vw;
    }
}

@media (max-width: 961px) {
    .same-packs__title {
        font-size: 7.47vw;
        text-align: center;
    }
}

@media (max-width: 961px) {
    .same-packs__list {
        width: 74.67vw;
        max-width: 74.67vw;
        gap: 8.27vw;
    }
}

@media (max-width: 961px) {
    .same-packs__item {
        width: 100%;
    }

    .same-packs__item:nth-child(n + 4) {
        margin-top: 0;
    }
}

@media (max-width: 961px) {
    .same-packs__wr-link {
        width: 100%;
        min-height: 104.80vw;

        border-radius: 8vw;
    }
}

@media (max-width: 961px) {
    .same-packs__link {
        border-radius: 7.4vw;
    }
}

@media (max-width: 961px) {
    .same-packs__wr-animate-img {
        display: none;
    }
}

@media (max-width: 961px) {
    .same-packs__wr-pack-img_pyramid { 
        width: 49.60vw;
    }

    .same-packs__wr-pack-img_dellipack {
        width: 42.40vw;
    }

    .same-packs__wr-pack-img_tea-pot {
        width: 59.40vw;
        margin-top: -4vw;
    }

    .same-packs__wr-pack-img_leaf {
        width: 50.40vw;
        margin-top: -4vw;
    }

    .same-packs__wr-pack-img_top-selection {
        width: 44.5vw;
        margin-top: -2vw;
    }
}

@media (max-width: 961px) {
    .same-packs__wr-description {
        width: 68vw;
        height: 31.47vw;

        border-radius: 4.27vw;

        justify-content: center;
    }
}

@media (max-width: 961px) {
    .same-packs__wr-description p:first-child {
        margin-top: -4.5vw;

        font-size: 6.40vw;
    }
    
    .same-packs__wr-description p:last-child {
        font-size: 5.33vw;
    }
}

