.search__container {
  position: relative;
  display: flex;
  flex-direction: row;
}
 
.box {
  position: absolute;
  right: 60px;
  order: -1;
  max-height: 3vw;
  opacity: 1;
  width: 0;
  height: 2.6vw;
  overflow: hidden;
  margin-bottom: 0px;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
}

#toggle {
  width: 0;
  display: none;
}
#toggle + label:after {
  text-align: start;
  display: inline-block;  
  border-radius: 10px;
  padding: 5px 20px;
  cursor: pointer;
}
.header-wrapper--main #toggle + label:after {
  content: "";
  
  height: 2.6vw;

  background: no-repeat url("../img/icons/icon-search.svg");
  background-position: center;
  background-size: contain;
}

 #toggle + label:after {
  content: "";

  height: 2.5vw;
  width: 2.1vw;

  background: no-repeat url("../img/icons/search-white.svg");
  background-position: center;
  background-size: contain;
}


.header-wrapper--main #toggle + label:hover {
  filter: drop-shadow(3px 2px 3px rgba(0, 0, 0, 0.7));
  -webkit-filter: drop-shadow(3px 2px 3px rgba(0, 0, 0, 0.7));
}

.header-wrapper--main #toggle:checked + label:after {
  content: "";

  height: 2.6vw;     
  
  background: no-repeat url("../img/icons/close.svg");
  background-position: center;
  background-size: contain;
}

 #toggle:checked + label:after {
  content: "";

  height: 2.5vw;
  width: 2.1vw;

  background: no-repeat url("../img/icons/close-white.svg");
  background-position: center;
  background-size: contain;
}

 #toggle + label:hover {
  filter: drop-shadow(3px 2px 3px rgba(255, 255, 255, 0.7));
  -webkit-filter: drop-shadow(3px 2px 3px rgb(255, 255, 255));
}

.marnifier {
  stroke: blue;
}

#toggle:checked ~ .box {
  width: 53vw;
  /* margin-right: 25px; */
  margin-right: 50px;
  box-shadow: 0px 0px 6px 2px rgba(255, 255, 255, 0.7);
  opacity: 1;
  transition: width 0.5s ease-in-out;
  border-radius: 2.6vw 0px 2.6vw 2.6vw;
  -webkit-transition: width 0.5s ease-in;
  -moz-transition: width 0.5s ease-in;
  -ms-transition: width 0.5s ease-in;
  -o-transition: width 0.5s ease-in;
}

.header-wrapper--main #toggle:checked ~ .box {
  box-shadow: 0px 0px 6px 2px rgba(24, 23, 23, 0.41);
}

.input__field {
width: 100%;
height: 100%;
padding-left: 25px;
border: none;
}

.input__group {
  height: 2.6vw;
}

.search-item {
  height: 2.6vw;
}




@media (max-width: 961px) {
  #toggle + label:after {
    content: "";
  
    height: 100%;
    width: 100%;
    padding: 0;
  
    background: no-repeat url("../img/icons/search-white.svg");
    background-position: center;
    background-size: contain;
  }

  #toggle:checked + label:after {
    content: "";
  
    height: 100%;
    width: 100%;
  
    background: no-repeat url("../img/icons/close-white.svg");
    background-position: center;
    background-size: contain;
  }
}

@media (max-width: 961px) {
  #toggle:checked ~ .box {
    width: 63vw;
    margin-right: 0;
  }
}

@media (max-width: 961px) {
  .box {
    right: 12.80vw;

    max-height: fit-content;
    height: 6vw;
  }
}

@media (max-width: 961px) {
  .input__group {
    height: 100%;
  }
}

@media (max-width: 961px) {
  .input__field {
    padding-right: 14.3vw;
  }
}