
.cards {
    width: 89%;
    display: flex;   
    flex-wrap: wrap; 
    justify-content: space-between;
    padding-left: 35px;
    padding-right: 35px;    
} 

.card-container { 
    position: relative;
    padding: 2px 2px;
}


/* media */
@media (max-width: 961px) {
    .cards {
        flex-direction: column; 
        margin-left: auto;
        margin-right: auto;
    }    
}

.wr-recipes { 
    margin-top: 1.25vw;
}


.recepies {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.recepies h2 {
  margin-bottom: 4.20vw;

  font-size: 2.86vw;
}

.recept__card {
    position: relative;
    text-align: center;    
    width: 19.90vw;
    height: 26.56vw;
    flex-shrink: 0;
    border-radius: 2.5vw;
    overflow: hidden;      
    background: linear-gradient(180deg, #7775B6 0%, #494694 69.79%);   
     
}

.recept__card--wr-img {    
    min-height: 90%;
    /* width: 90%; */
    width: 75%;
    padding-top: 0.68vw;
    z-index: 1;
    object-fit: scale-down;
    margin-left: auto;
    margin-right: auto;
    border-radius: 16px;  
}

.recept__card--img_mobile {
    display: none;
}

.recept__description {
    position: absolute;
    top: 82%;
    left: 5%;  

    width: 90%;
    height: 40%;
    padding-left: 15px;
    padding-right: 15px;

    border-radius: 16px;
    background: linear-gradient(180deg, hsla(217, 38%, 53%, 0.941) 54.47%, rgba(43, 81, 107, 0.00) 100%);
    backdrop-filter: blur(3px);
    box-shadow: 0px 0px 0px 0px rgba(8, 43, 34, 0.10), -1px -4px 9px 0px rgba(8, 43, 34, 0.10), -4px -16px 17px 0px rgba(8, 43, 34, 0.09), -9px -37px 23px 0px rgba(8, 43, 34, 0.05), -16px -66px 27px 0px rgba(8, 43, 34, 0.01), -26px -102px 30px 0px rgba(8, 43, 34, 0.00);

    transition: 1s;

    flex-shrink: 0;
    z-index: 1000;
}

.recept__description--opened {
    height: inherit;        
    transition: 1s;
    margin-top: 35%;
    top: 0;      
}

.description-arrow {
    margin-top: 0.5vw;
    margin-bottom: 0;
    height: 12.5%;
    width: 12.5%;
    cursor: pointer;
    filter: none;
    transition: filter 0.4s ease; 
}

.arrowDown {    
    transform: rotate(180deg);    
}

.recept__description {
    border: #2A4A82;
}

.recept__description h3 {
    color: #FFF;
    font-family: Blogger Sans;
    font-size: 1.875vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;    
    margin-top: 0px;      
    margin-bottom: 0;
}

.recept__description--text {
    display: none;
    overflow: hidden;
    color: #FFF;
    font-family: Blogger Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    text-align: start;  
    margin-top: 15px;  
}

.recept__description--text p {
  font-size: 14px;
  margin-bottom: 0;
}

 .ingridients {
    font-size: 0.73vw;
    text-decoration: dotted;        
    margin-left: 0;
 }

 .ingridients_two-column {
    display: flex;
    height: 3.75vw;

    flex-direction: column;
    flex-wrap: wrap;
}

 
 .active_card .description-arrow {
  transform: rotate(180deg);  
 }

 .description-arrow:hover {
    filter: drop-shadow(0px 3px 3px rgba(255, 255, 255, 0.514));
    transition: filter 0.4s ease; 
 }



.recepies__button {
    position: static;

    width: 19.27vw;
    height: 3.65vw;
    margin-top: 4.9vw;

    font-size: 1.41vw !important;

    background: linear-gradient(180deg, #00BD8D 50%, #00392D) !important;
}

.recepies__button span {
    background: #45C2A2 !important;
}

.recept__card--back {
    text-align: center;
      border-radius: 50px;
      overflow: hidden;
    
  }
  
  .recept__card--back::after {  
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px; 
    border-radius: 2.5vw;
    border: 3px solid transparent; 
    border-image-source: linear-gradient(#00BD8D 32.29%, #00392D 100%) border-box;
    background: linear-gradient(180deg, #00BD8D 32.29%, #00392D 100%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;  
    box-shadow: 0px 0px 0px 0px rgba(8, 43, 34, 0.10), -1px -4px 9px 0px rgba(8, 43, 34, 0.10), -4px -16px 17px 0px rgba(8, 43, 34, 0.09), -9px -37px 23px 0px rgba(8, 43, 34, 0.05), -16px -66px 27px 0px rgba(8, 43, 34, 0.01), -26px -102px 30px 0px rgba(8, 43, 34, 0.00), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);    
  }
  

.recepies-row {
    display: grid;
    max-height: 477px;
    grid-template-columns: 50% 50%;
    column-gap: 50px;
    justify-content: space-around;
}

.recepies-img {
    object-fit: cover;
    width: 100%;    
    height: 100%;
    box-shadow: 0px 0px 0px 0px rgba(0, 25, 19, 0.10), 13px 13px 41px 0px rgba(0, 25, 19, 0.10), 54px 52px 75px 0px rgba(0, 25, 19, 0.09), 121px 118px 101px 0px rgba(0, 25, 19, 0.05), 215px 209px 120px 0px rgba(0, 25, 19, 0.01), 336px 327px 131px 0px rgba(0, 25, 19, 0.00);
}

.recepies__text--col {
    display: grid;
        box-sizing: border-box;   
        height: 100%;
        width: 100%; 
        border-radius: 50px;
        padding-left: 40px;
        padding-right: 40px;
        background: url(../img/icons/star.svg), url(../img/icons/2stars-contacts-left.png), linear-gradient(180deg, #7775B6 0%, #494694 69.79%);
        background-size: 20% cover;
        background-position-x: 3%, 96%;
        background-position-y: 9%, 18%;
        background-repeat: no-repeat;
        box-shadow: 0px 0px 0px 0px rgba(0, 25, 19, 0.10), 13px 13px 41px 0px rgba(0, 25, 19, 0.10), 54px 52px 75px 0px rgba(0, 25, 19, 0.09), 121px 118px 101px 0px rgba(0, 25, 19, 0.05), 215px 209px 120px 0px rgba(0, 25, 19, 0.01), 336px 327px 131px 0px rgba(0, 25, 19, 0.00);
        z-index: 1;    
        color: #FFF;
        font-family: 'Blogger Sans';
        font-size: 24px;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
}


.recepies__name{
        color: #FFC300;
        font-family: 'Arsenal';
        font-size: 60px;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
        padding-top: 10px;
    }

.recepies__method {
    padding-bottom: 10px;
    padding-top: 10px;
    margin: 0;
}

.ingridients-list {
    width: 100%;      
    column-count: 2;
    column-fill: balance;
    column-width: 25rem;
    column-gap: 54px;
    list-style: none;
    margin: auto;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}


.ingridients__item::before {
    content: '';

    display: inline-block;
    height: 3px;
    width: 3px;
    margin-right: 0.21vw;

    background-color: #FFF;
    border-radius: 50%;
    vertical-align: middle;
}

.recepies__header {
    width: 100%;
    text-align: center;
}

@media(max-width: 1600px) {
    .recept__description--opened {
        top: -79px;
    }
}

@media (max-width: 1400px) {
    .recepies__method {
        font-size: 18px;
    }

    .recepies__text--col span {
        font-size: 18px;
    }

    .recepies__name {
        font-size: 48px;
    }

}

@media (max-width: 961px) {
    .recepies__method {
        font-size: 18px;
    }
}

@media (max-width: 961px) {
    .recept__card--wr-img {
        display: flex;
        min-height: 100%;
    }
}

@media (max-width: 961px) {
    .recept__card--img {
        display: none;
    }
}

@media (max-width: 961px) {
    .recept__card--img_mobile {
        display: block;
        height: 100vw;
        width: 100%;

        flex-grow: 1;
    }

    .recept__card--img-1_mobile {
        background: no-repeat url(../img/recipes-img-1-mobile.png);
        background-size: 85%;
        background-position: top -3vw center;
    }

    .recept__card--img-2_mobile {
        background: no-repeat url(../img/recipes-img-2-mobile.png);
        background-size: 85%;
        background-position: top -3vw center;
    }

    .recept__card--img-3_mobile {
        background: no-repeat url(../img/recipes-img-3-mobile.png);
        background-size: 85%;
        background-position: top -3vw center;
    }
}

@media (max-width: 961px) and (orientation: landscape ) {
    .recept__description {
        top: 86.5%;

        border-radius: 8vw;
    }
}

@media (max-width: 961px) and (orientation: portrait ) {
    .recept__description {
        top: 83.5%;

        border-radius: 8vw;
    }
}

@media (max-width: 961px) {
    

    .recept__description--text {
        margin-top: 22px;

        font-size: 3.47vw;
    }

    .recept__description--text p {
        font-size: 3.47vw;
    }

    .recept__description h3 {
        font-size: 6.40vw;
    }

    .ingridients {
        padding-left: 3vw;

        font-size: 3.47vw;
    }

    .ingridients__item::before {
        margin-right: 1.07vw;
    }

    .ingridients_two-column {
        height: 17.07vw;
    }

    .description-arrow {
        height: 4.5vh;
    }

    .recepies__button {
        height: 11.73vw; 
        width: 89%;
        margin-top: 6.67vw;

        font-size: 4.27vw !important;
    }

}
/* media */
@media (max-width: 961px) {
    .recept__card--img-1_mobile {
        background-size: 93%;
    }

    .recept__card--img-2_mobile {
        background-size: 93%;
    }

    .recept__card--img-3_mobile {
        background-size: 93%;
    }
}

@media(max-width: 961px) {
    .wr-recipes {
        margin-top: 10.67vw;
    }
}

@media (max-width: 961px) {
    .recept__card {
      width: 100%;
      height: 94.93vw;    
      border-radius: 13.33vw;
    }
  
    .recept__card--back {
      border-radius: 13.33vw;
    }
  
    .recept__card--back::after {
    border-radius: 13.33vw !important;
    }
  
}

@media(max-width: 961px) {
    .recept__description--opened {
        top: -27%;
    }
}

@media (max-width: 768px) and (orientation: portrait) {
    .recept__description {
        top: 81%;
    }
}

@media (max-width: 768px) and (orientation: landscape) {
    .recept__description {
        top: 84.5%;
    }
}

@media (max-width: 768px) {
    .recept__description--opened {
        transform: translateY(-89vw);
    }
}

@media(max-width: 768px) {
    .recept__description--opened {
        top: 77%;
        /* padding-bottom: 18.2vw; */

        transform: translateY(-102%); /*29%*/
    }
}

@media(max-width: 500px) {
    .recept__description {
        top: 78%;
    }
}

@media(max-width: 400px) and (orientation: landscape) {
    .recept__description {
        top: 83.5%;
    }
}

@media(max-width: 400px) {
    .recept__description--opened {
        padding-bottom: 18.2vw;

        transform: translateY(-101.5%); /*29%*/
    }
}

@media(max-width: 375px) {
    .recept__description--opened {
        transform: translateY(-98%); /*29%*/
    }
}




