.prc__wr-title-slider {
    margin-top: 9.11vw;
}

.prc__title-slider {
    margin-bottom: 0;

    font-family: Arsenal;
    font-size: 2.08vw;
    font-weight: 400;
    text-align: center;

    color: #ffffff;
}

.prc__wr-slider {
    display: flex;
    margin-top: 3.44vw;
    margin-bottom: 14vw;

    justify-content: center;
}

.prc__wr-slider_mobile {
    display: none;
}


@media (max-width: 961px) {
    .prc__wr-title-slider {
        margin-top: 16.40vw;
    }
}

@media (max-width: 961px) {
    .prc__title-slider {
        font-size: 7.47vw;
        text-align: center;
    }
}

@media (max-width: 961px) {
    .prc__wr-slider {
        display: none;
    }
}

@media (max-width: 961px) {
    .prc__wr-slider_mobile {
        display: block;
        margin-top: 14.93vw;
        margin-bottom: 64.27vw;
    }
}