
.cup-steam section{    
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  } 
    .vapour  span{
        position: relative;
        bottom: 10px;
        display: block;       
        min-width: 8px;
        margin-left: auto;
        margin-right: auto;
        height: 120px;
        background-color: #fff;
        border-radius: 10%;
        animation: steamanimation 5s linear infinite;
        opacity: 0;
        filter: blur(12px);
        animation-delay: calc(var(--i)* -0.5s);      
    }

    .vapour{
        margin-left: auto;
        margin-right: auto;
        position: relative;
        display: flex;
        z-index: 1;
        transform: translate(-55%);        
    }


  .cup-steam {
    text-align: center;
    width: 100%;
    padding-top: 250px;
    background-color:rgba(0, 0, 0, 0.25)
  }

  .cup-steam img{
    margin-left: auto;
    margin-right: auto;
  }

  @keyframes steamanimation{
    0%{
      transform: translateY(0) scaleX(1);
      opacity:0;
    }
    15%{
      opacity:0.4;
    }
    50%{
      transform: translateY(-150px) scaleX(5);
    }
    95%{
      opacity:0;
    }
    100%{
      transform: translateY(-200px) scaleX(10);
    }
  }


  .card {
    --background: linear-gradient(to left, #f7ba2b 0%, #ea5358 100%);
    width: 190px;
    height: 254px;
    padding: 5px;
    border-radius: 1rem;
    overflow: visible;
    background: #f7ba2b;
    background: var(--background);
    position: relative;
    z-index: 1;
   }
   
   .card::after {
    position: absolute;
    content: "";
    top: 30px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    transform: scale(0.8);
    filter: blur(25px);
    background: #f7ba2b;
    background: var(--background);
    transition: opacity .5s;
   }
   
   .card-info {
    --color: #181818;
    background: var(--color);
    color: var(--color);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: visible;
    border-radius: .7rem;
   }
   
   .card .title {
    font-weight: bold;
    letter-spacing: .1em;
   }
   
   /*Hover*/
   .card:hover::after {
    opacity: 0;
   }
   
   .card:hover .card-info {
    color: #f7ba2b;
    transition: color 1s;
   }
   


   

.glow-on-hover {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

