.breadcrumbs__wrapper {
    display: flex;
    margin-top: 1.25vw;

    justify-content: center;
}

.breadcrumbs__wrapper-recipes {
    padding-right: 7.3vw;
}

.breadcrumbs__list {
    display: flex;
    width: 70%;
    gap: 0.36vw;
    padding-left: 0;

    align-items: center;
}

.breadcrumbs__item__link {
    font-family: Blogger Sans;
    font-size: 0.73vw;
    font-weight: 400;

    color: #ffffff;
}

.breadcrumbs__slash {
    font-family: Blogger Sans;
    font-size: 1.04vw;
    font-weight: 400;

    color: #ffffff;
}

.breadcrumbs__active {
    font-weight: 500;
}

@media (max-width: 961px) {
    .breadcrumbs__wrapper {
        display: none;
    }
}